/* eslint-disable dot-notation */
import Outline from '@lemonize/outlining';
import VueSmoothScroll from 'vue3-smooth-scroll';
import CountUp from './directives/count';
import CopyContent from './directives/copy';
import Pinned from './directives/pinned';
import HideContent from './directives/hide';
import Animate from './directives/animate';
import HoverPlay from './directives/hoverPlay';
import SearchCTA from './directives/searchCta';
import ScrollTo from './directives/scrollTo';
import Dummy from './components/BizDummy.vue';
import BizSlider from './components/BizSlider.vue';
import BizSlide from './components/BizSlide.vue';
import ShareButton from './components/ShareButton.vue';
import StickyItem from './components/StickyItem.vue';
import ProgressBar from './components/ProgressBar.vue';
import MainNavigation from './components/navigation/MainNavigation.vue';
import MainNavItem from './components/navigation/MainNavItem.vue';
import ShowMore from './components/base/ShowMore.vue';
import HeroTextMediaColumns from './components/HeroTextMediaColumns.vue';
import HeroTextMediaColumnsItem from './components/HeroTextMediaColumnsItem.vue';
import InitialColumns from './components/base/InitialColumns.vue';
import BizOverview from './components/overview/BizOverview.vue';
import OverviewItem from './components/overview/OverviewItem.vue';
import OverviewCategory from './components/overview/OverviewCategory.vue';
import OverviewFilter from './components/overview/OverviewFilter.vue';
import OverviewSearch from './components/overview/OverviewSearch.vue';
import BizDropdown from './components/base/BizDropdown.vue';
import DropdownItem from './components/base/DropdownItem.vue';
import ReadMore from './components/base/ReadMore.vue';
import AutocompleteInput from './components/AutocompleteInput.vue';
import HighlightTab from './components/HighlightTab.vue';
import HighlightTabs from './components/HighlightTabs.vue';
import NavItem from './components/navigation/NavItem.vue';
import ProductNavigation from './components/navigation/ProductNavigation.vue';
import ProductCategory from './components/navigation/ProductCategory.vue';
import BizAccordion from './components/base/BizAccordion.vue';
import CtaShow from './components/CtaShow.vue';
import FormDropdown from './components/FormDropdown.vue';
import MetaNavItem from './components/navigation/MetaNavItem.vue';
import LightBox from './components/LightBox.vue';
import BizSearch from './components/navigation/BizSearch.vue';
import CtaForm from './components/CtaForm.vue';
import SiteNavigation from '@/components/navigation/SiteNavigation.vue';
import SiteNavItem from '@/components/navigation/SiteNavItem.vue';
import ProductVariants from '@/components/ProductVariants.vue';
import ProductVariant from '@/components/ProductVariant.vue';
import ProductOverview from '@/components/overview/ProductOverview.vue';
import ProductFilter from '@/components/overview/ProductFilter.vue';
import ProductSliders from '@/components/ProductSliders.vue';
import BizMap from '@/components/BizMap.vue';
import ProductFinder from '@/components/ProductFinder.vue';
import StickyNavigation from '@/components/navigation/StickyNavigation.vue';
import CampaignPopup from '@/components/campaigns/CampaignPopup.vue';
import CampaignHeader from '@/components/campaigns/CampaignHeader.vue';
import CampaignStage from '@/components/campaigns/CampaignStage.vue';
import CampaignStageItem from '@/components/campaigns/CampaignStageItem.vue';
import OpenPopup from '@/directives/openPopup';
import GatedDownload from '@/components/campaigns/GatedDownload.vue';
import ContentNavigation from '@/components/campaigns/ContentNavigation.vue';
import FaqOverview from '@/components/overview/FaqOverview.vue';
import FaqHeader from '@/components/navigation/FaqHeader.vue';
import Autoplay from '@/directives/autoplay';
import FriendlyCaptcha from '@/components/base/FriendlyCaptcha.vue';
import CareerDescription from '@/components/careers/CareerDescription.vue';
import CareerList from '@/components/careers/CareerList.vue';
import CareerOthers from '@/components/careers/CareerOthers.vue';

export const PRODUCTS_REST_ENDPOINT = '/.rest/api/v1/products';
export const FAQ_REST_ENDPOINT = '/.rest/api/v1/faq';

// vue plugin to register all vue dependencies of this module
export default {
    install(app) {
        /* plugins */
        app.use(Outline);
        app.use(VueSmoothScroll);

        /* directives */
        app.directive('count', CountUp);
        app.directive('copy', CopyContent);
        app.directive('hide', HideContent);
        app.directive('animate', Animate);
        app.directive('hoverplay', HoverPlay);
        app.directive('searchcta', SearchCTA);
        app.directive('popup', OpenPopup);
        app.directive('scroll', ScrollTo);
        app.directive('pinned', Pinned);
        app.directive('autoplay', Autoplay);

        /* global properties */
        app.config.globalProperties.$contextPath = window['contextPath'];
        app.config.globalProperties.$resourcePath = window['resourcePath'];
        app.config.globalProperties.$siteName = window['siteName'];
        app.config.globalProperties.$lang = window['lang'];
        app.config.globalProperties.$prototype = window['prototype'];
        app.config.globalProperties.$editMode = window['editMode'];
        // also provide them so we can use them in composition api components with `inject`
        app.provide('contextPath', window['contextPath']);
        app.provide('resourcePath', window['resourcePath']);
        app.provide('siteName', window['siteName']);
        app.provide('lang', window['lang']);
        app.provide('prototype', window['prototype']);
        app.provide('editMode', window['editMode']);
        /* Components */
        app.component('biz-dummy', Dummy);
        app.component('biz-slider', BizSlider);
        app.component('biz-slide', BizSlide);
        app.component('share-button', ShareButton);
        app.component('sticky-item', StickyItem);
        app.component('progress-bar', ProgressBar);
        app.component('main-navigation', MainNavigation);
        app.component('main-nav-item', MainNavItem);
        app.component('nav-item', NavItem);
        app.component('site-navigation', SiteNavigation);
        app.component('site-nav-item', SiteNavItem);
        app.component('show-more', ShowMore);
        app.component('read-more', ReadMore);
        app.component('hero-text-media-columns', HeroTextMediaColumns);
        app.component('hero-text-media-columns-item', HeroTextMediaColumnsItem);
        app.component('initial-columns', InitialColumns);
        app.component('biz-overview', BizOverview);
        app.component('overview-item', OverviewItem);
        app.component('overview-category', OverviewCategory);
        app.component('overview-search', OverviewSearch);
        app.component('overview-filter', OverviewFilter);
        app.component('biz-dropdown', BizDropdown);
        app.component('biz-accordion', BizAccordion);
        app.component('dropdown-item', DropdownItem);
        app.component('autocomplete-input', AutocompleteInput);
        app.component('highlight-tabs', HighlightTabs);
        app.component('highlight-tab', HighlightTab);
        app.component('product-navigation', ProductNavigation);
        app.component('product-category', ProductCategory);
        app.component('cta-show', CtaShow);
        app.component('form-dropdown', FormDropdown);
        app.component('meta-nav-item', MetaNavItem);
        app.component('light-box', LightBox);
        app.component('biz-search', BizSearch);
        app.component('cta-form', CtaForm);
        app.component('product-variants', ProductVariants);
        app.component('product-variant', ProductVariant);
        app.component('product-overview', ProductOverview);
        app.component('product-filter', ProductFilter);
        app.component('product-sliders', ProductSliders);
        app.component('biz-map', BizMap);
        app.component('product-finder', ProductFinder);
        app.component('sticky-navigation', StickyNavigation);
        app.component('campaign-popup', CampaignPopup);
        app.component('campaign-header', CampaignHeader);
        app.component('campaign-stage', CampaignStage);
        app.component('campaign-stage-item', CampaignStageItem);
        app.component('gated-download', GatedDownload);
        app.component('content-navigation', ContentNavigation);
        app.component('faq-overview', FaqOverview);
        app.component('faq-header', FaqHeader);
        app.component('friendly-captcha', FriendlyCaptcha);
        app.component('career-description', CareerDescription);
        app.component('career-list', CareerList);
        app.component('career-others', CareerOthers);
    }
};
