import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sticky top-[20%] z-20 hidden h-0 w-fit transform lg:ml-4 lg:block" }
const _hoisted_2 = { class: "py-40" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_smooth_scroll = _resolveDirective("smooth-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.anchor
        }, [
          (item.anchor && item.title)
            ? _withDirectives((_openBlock(), _createElementBlock("a", {
                key: 0,
                href: '#' + item.anchor,
                class: "group flex"
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["h-8 w-0.5", [
                            _ctx.contrast ? 'group-hover:bg-white' : 'group-hover:bg-gray-500',
                            {
                                'bg-white/10': _ctx.active !== item.anchor && _ctx.contrast,
                                'bg-white': _ctx.active === item.anchor && _ctx.contrast,
                                'bg-gray-400/10': _ctx.active !== item.anchor && !_ctx.contrast,
                                'bg-gray-500': _ctx.active === item.anchor && !_ctx.contrast
                            }
                        ]])
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["ml-5 group-hover:block", [
                            _ctx.contrast ? 'text-white' : 'text-gray-500',
                            _ctx.active === item.anchor ? '' : 'hidden'
                        ]])
                }, _toDisplayString(item.title), 3)
              ], 8, _hoisted_3)), [
                [_directive_smooth_scroll, { updateHistory: false }]
              ])
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}