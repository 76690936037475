
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon, CollapseTransition },
    props: {
        hero: {
            type: Boolean,
            default: false
        },
        color: {
            type: Boolean,
            default: false
        },
        dropdown: {
            type: Boolean,
            default: true
        },
        label: {
            type: String
        },
        anchor: {
            type: String,
            default: 'cta'
        },
        showSitckyButton: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            open: false,
            showButton: false,
            startHide: false
        };
    },
    computed: {
        iconClasses() {
            return {
                'm-auto h-5 w-5 pr-[2px] fill-white group-hover:fill-cta-blue': true,
                'animate-opacity-delay opacity-0': this.startHide,
                'animate-opacity-hide opacity-1': this.startHide
            };
        },
        firstStickyButton() {
            // only display the first cta sctiky button
            return !document.getElementById('cta-sticky');
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const documentHeight = document.documentElement.scrollHeight;
            const windowHeight = window.innerHeight;

            const footerElement = document.querySelector('footer');
            const footerHeight = footerElement ? footerElement.offsetHeight : 200;
            const scrollUntilFooter = documentHeight - windowHeight - footerHeight;
            const futureShowButton = scrollTop >= 30 && scrollTop <= scrollUntilFooter;

            if (this.showButton !== futureShowButton && !futureShowButton && !this.startHide) {
                // start hide text
                this.startHide = true;
                // hide
                setTimeout(() => {
                    this.showButton = false;
                    this.startHide = false;
                }, 1200);
            } else if (this.showButton !== futureShowButton && futureShowButton) {
                // start circle animation
                this.startHide = false;
                this.showButton = true;
            }
        },
        scrollToAnchor() {
            const element = document.getElementById(this.anchor);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
});
